body {
    padding-bottom: 40px;
    background-color: #0b4d73; /*#F5F5F5;*/
    color: #fff;
    font-family: "IBM Plex Sans", sans-serif;
    line-height: 1.75;
}
.jumbotron {
    background-color: #fff;
    color: #333;
    margin-top: 30px;
    padding-bottom: 30px;
}
.jumbotron h1 {
    font-weight: bold;
    font-family: "IBM Plex Serif", sans-serif;
}
.jumbotron a {
    color: #333;
}
.jumbotron a:hover {
    color: #333;
    border-bottom: 1px solid #333;
}
h2 {
    font-weight: 600;
    font-size: 26px;
    font-family: "IBM Plex Serif", sans-serif;
    margin-bottom: 20px;
}
h6 {
    font-weight: 200;
    color: #666;
    margin-bottom: 0;
}
h6 a:hover {
    text-decoration: none;
}
a,
a:focus,
a:visited {
    color: #fff;
    border-bottom: 1px dotted #ccc;
    text-decoration: none;
}
p a:hover,
li a:hover,
footer a:hover {
    color: #fff;
    border-bottom: 1px solid #fff;
    text-decoration: none;
}
p a:visited,
li a:visited {
    color: #fff;
    border: 0;
    text-decoration: none;
}
blockquote {
    font-size: 16px;
    border: none;
    font-style: italic;
    margin: 14px 0;
}
blockquote footer {
    font-style: normal;
    color: white;
    padding-left: 20px;
}
h6.small-print {
    margin-top: 30px;
}
h6.small-print a:visited {
    color: #666;
}
.fb_iframe_widget span {
    vertical-align: baseline !important;
}
.glyphicon {
    font-size: 30px;
}
a.btn.btn-danger {
    background-color: #ec5a62;
}
