.ntg-red {
    color: #ec5a62;
}

.ntg-blue {
    color: #0b4d73;
}
ul.dotme {
    list-style-type: disc;
}
