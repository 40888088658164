.Header {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Header__logo {
    width: 10em;
    height: auto;
    margin: 0.5em 0 1em;
}

.Header__actions {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-grow: 1;
}

a.Header__logo-link {
    text-decoration: none;
    border: 0;
}

a.Header__logo-link:hover {
    border: 0;
}

@media (min-width: 480px) {
    .Header {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
    }

    .Header__logo {
        margin: 0.5em 0;
    }

    .Header__actions {
        width: auto;
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
    }

    .Header__actions > * {
        margin: 0;
    }
}
ul {
    list-style-type: none;
    margin: 0;
    padding-left: 1em;
}
