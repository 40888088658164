.jumbotron a.RandomizerButton,
a.RandomizerButton,
.RandomizerButton {
    display: inline-block;
    background-color: #ec5a62;
    color: #fff;
    border: 0;
    transition: 200ms all;
    margin-top: 20px;
}

.jumbotron a.RandomizerButton:hover,
a.RandomizerButton:hover,
.RandomizerButton:hover {
    color: #fff;
    background-color: #0b4d73;
    border: 0;
}
