.SearchField {
    width: 100%;
    min-width: 15em;
    max-width: 25em;
    margin: 1em 0.5em 0;
}

@media (min-width: 480px) {
    .SearchField {
        margin: 0;
    }
}
